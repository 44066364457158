import '../css/app.css';
import './bootstrap';
import 'primeicons/primeicons.css';
//import '../assets/tailwind.css';
import '../css/styles.css';
import { createInertiaApp, Head, Link } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createApp, h } from 'vue';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
//import Container from './Components/Container.vue';
//import FrontPageLayout from './Layouts/FrontPageLayout.vue';
import MyPreset from '@/mytheme.js';
import { useDark } from '@vueuse/core';
import { i18nVue } from 'laravel-vue-i18n'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';


const darkMode = useDark();
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        const page = await resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue'),
        );
        page.default.layout ??= undefined; // Prevent double-wrapping
        return page;
    },
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .provide('darkMode', darkMode)
            .use(PrimeVue, {
                theme: MyPreset,
                ripple: true,

            })
            .component('Head', Head)
            .component('Link', Link)
            //.component('Container', Container)
            //.component('FrontPageLayout', FrontPageLayout)
            .directive('tooltip', Tooltip)
            .use(plugin)
            .use(ZiggyVue)
            .use(ToastService)
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                }
            })

            .mount(el);
    },
    progress: {
        color: '#4f46e5',
    },
});
